<template>
    <div>
        <footer id="pie_nwi_copyright">
            <p>© Universidad de Las Palmas de Gran Canaria · ULPGC</p>
        </footer> 
    </div>
</template>

<script>
export default {
    name: 'FooterMiulpgc',
};
</script>

<style scoped>
    
#pie_nwi_copyright {
    background: rgb(0,46,103);
    height: 30px;
    position: fixed;
    bottom: 0;
    width: 100vw;
}
p {
    margin: 0;
    text-align: center;
    font-weight: lighter;
    color: #FFFFFF;
    font-size: 14px;
}
</style>